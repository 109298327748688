import React from 'react'
// import { Text } from 'rebass'
// import { ChainId, Currency, currencyEquals, ETHER, Token } from '@bscswap/sdk'
import { ChainId, Currency } from '@bscswap/sdk'
// import styled from 'styled-components'

// import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
// import QuestionHelper from '../QuestionHelper'
// import { AutoRow } from '../Row'
// import CurrencyLogo from '../CurrencyLogo'
// import { useTranslation } from 'react-i18next'

// const BaseWrapper = styled.div<{ disable?: boolean }>`
//   border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
//   border-radius: 10px;
//   display: flex;
//   padding: 6px;

//   align-items: center;
//   :hover {
//     cursor: ${({ disable }) => !disable && 'pointer'};
//     background-color: ${({ theme, disable }) => !disable && theme.bg2};
//   }

//   background-color: ${({ theme, disable }) => disable && theme.bg3};
//   opacity: ${({ disable }) => disable && '0.4'};
// `

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency
}: {
  chainId?: ChainId
  selectedCurrency?: Currency
  onSelect: (currency: Currency) => void
}) {
  // const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      {/* <AutoRow>
        <Text fontWeight={500} fontSize={14}>
          {t('commonBases')}
        </Text>
        <QuestionHelper text={t('commonlyPaired')} />
      </AutoRow> */}
      {/* <AutoRow gap="4px">
        <BaseWrapper
          onClick={() => !currencyEquals(selectedCurrency, ETHER) && onSelect(ETHER)}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontWeight={500} fontSize={16}>
            BNB
          </Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <Text fontWeight={500} fontSize={16}>
                {token.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow> */}
    </AutoColumn>
  )
}
