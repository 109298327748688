import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { TokenList, Version } from '@uniswap/token-lists'
import tokenList from '../../constants/tokenlist.json';

/**
 * Contains the logic for resolving a URL to a valid token list
 * @param listUrl list url
 */
async function getTokenList(listUrl: string): Promise<TokenList> {
 
  // for (const url of urls) {
  //   let response
  //   try {
  //     response = await fetch(url , {
  //       mode: 'cors',
  //       method: 'GET',
  //       cache: 'no-cache',
  //       headers: {
  //         'Content-Type': 'application/json'
  //         // 'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     })
  //     if (!response.ok) continue
  //   } catch (error) {
  //     console.error(`failed to fetch list ${listUrl} at uri ${url}`)
  //     continue
  //   }

  //   const json = tokenList;
  //   if (!tokenListValidator(json)) {
  //     throw new Error(
  //       tokenListValidator.errors?.reduce<string>((memo, error) => {
  //         const add = `${error.dataPath} ${error.message ?? ''}`
  //         return memo.length > 0 ? `${memo}; ${add}` : `${add}`
  //       }, '') ?? 'Token list failed validation'
  //     )
  //   }
    
  // }
  return tokenList;
  // throw new Error('Unrecognized list URL protocol.')
}

const fetchCache: { [url: string]: Promise<TokenList> } = {}
export const fetchTokenList = createAsyncThunk<TokenList, string>(
  'lists/fetchTokenList',
  (url: string) =>
    // this makes it so we only ever fetch a list a single time concurrently
    (fetchCache[url] =
      fetchCache[url] ??
      getTokenList(url).catch(error => {
        delete fetchCache[url]
        throw error
      }))
)

export const acceptListUpdate = createAction<string>('lists/acceptListUpdate')
export const addList = createAction<string>('lists/addList')
export const rejectVersionUpdate = createAction<Version>('lists/rejectVersionUpdate')
