import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, JSBI, Percent, Token, WETH } from '@bscswap/sdk';

// import { injected, binanceinjected, walletconnect, walletlink } from '../connectors'
import { injected, binanceinjected , walletconnect} from '../connectors'

export const ROUTER_ADDRESS = '0x1b9248ad376800aca986da82837e610e43f7bbf6'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')

export const B_USDT = new Token(ChainId.BSC_MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT Token')
export const BUSD = new Token(ChainId.BSC_MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'BUSD Token')
export const MF = new Token(ChainId.BSC_MAINNET, '0x6eE2a82C585503aCd1509d18f14Aa25e0a841763', 18, '$MF', 'MFVvip Token')
export const EARN = new Token(ChainId.BSC_MAINNET, '0xdb9e13d4f26d4d2A741EDbb65E67D27B4D824511', 18, '$EARN', 'EARN Token')
export const DLC = new Token(ChainId.BSC_MAINNET, '0x8076fc7FBd9cA04deAce0fe74A32deBFd07C6474', 18, '$DLC', '$DLC Token')
export const DLCV2 = new Token(ChainId.BSC_MAINNET, '0xfB74C8cB5b98Fd189e4960c787848C59e718806f', 18, '$DLCV2', '$DLCV2 Token')
export const FSC = new Token(ChainId.BSC_MAINNET, '0x2A0568E1949ED7B81d01c46391ab37A2ccd45683', 18, '$FSC', '$FSC Token')
export const GEP = new Token(ChainId.BSC_MAINNET, '0x0Bf3CeAe50467cbAd68ee60a7a5e7bC0C316a955', 18, '$GEP', '$GEP Token')
export const H2C = new Token(ChainId.BSC_MAINNET, '0xAB5EF8229E4E1509A2d46dFd3505613BcD28620C', 18, '$H2C', '$H2C Token')
export const LIFE = new Token(ChainId.BSC_MAINNET, '0x6b82EEEAEca3C3BDfD16c399c70Ae5002f753f26', 18, '$LIFE', '$LIFE Token')
export const NFT2E = new Token(ChainId.BSC_MAINNET, '0x9963773D9907e61F0eAE09d06F022D5D34c2E1D8', 18, '$NFT2E$', '$NFT2E Token')
export const P2EG = new Token(ChainId.BSC_MAINNET, '0x8269DE634ccb780C0dcae1D9bd1dE845c050af95', 18, '$P2EG', '$P2EG Token')
export const SUD = new Token(ChainId.BSC_MAINNET, '0x680474eBa2c85fC34b16357BE6c2d0DBDade1E68', 18, '$SUD', '$SUD Token')
export const GMT = new Token(ChainId.BSC_MAINNET, '0x5A649C750420752C2Cea2A812092FC7057D3f5FD', 18, 'GMT', 'GMT Token')



export const T_DAI = new Token(ChainId.BSC_TESTNET, '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', 18, 'DAI', 'Dai Token')
export const T_BUSD = new Token(ChainId.BSC_TESTNET, '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee', 6, 'BUSD', 'BUSD Token')
export const T_MACARON = new Token(ChainId.BSC_TESTNET, '0xDBC1a6CC0D733f397d3857AD0b7e5c0CCcde88f7', 5, 'MACARON', 'MACARON Token')

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSC_MAINNET]: [WETH[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], B_USDT, BUSD, EARN , DLC,DLCV2,FSC,GEP,H2C,LIFE,NFT2E,P2EG,SUD,GMT]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_MACARON, T_BUSD, T_DAI]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET],  B_USDT, BUSD, MF , EARN , DLC,DLCV2,FSC,GEP,H2C,LIFE,NFT2E,P2EG,SUD,GMT],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_MACARON, T_BUSD, T_DAI]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  BINANCE: {
    connector: binanceinjected,
    name: 'Binance Chain Wallet',
    iconName: 'bnb.svg',
    description: 'A Crypto Wallet for Binance Smart Chain',
    href: null,
    color: '#F9A825'
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  // TRUSTWALLET: {
  //   connector: injected,
  //   name: 'TrustWallet',
  //   iconName: 'trustwallet.svg',
  //   description: 'The most trusted & secure crypto wallet',
  //   href: null,
  //   color: '#3375BB'
  // },
  WALLETCONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletconnect.svg',
    description: 'Your Gateway to the World of Blockchain',
    href: null,
    color: '#000000'
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = `https://neuton.site/tokens/tokens.json`
