import React from 'react'

export default function Chart() {

  return (
    <>
      {/* <BodyWrapper> */}
      <h3>$MF Token Chart</h3>
        <iframe className="dexscreen-card" height="400px" width="80%" src="https://dexscreener.com/bsc/0x6eE2a82C585503aCd1509d18f14Aa25e0a841763" title="dexchart"></iframe>
      {/* </BodyWrapper> */}
    </>
  )
}
