import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { useDarkModeManager } from '../../state/user/hooks'
import './Footer.css'


const Link01: React.FC = () => {
    const [isDark] = useDarkModeManager()
    return (
      <FLink01B>
        <Link to="/swap"><StyledAbsoluteLinkA style={{ "color": isDark ? "#ffff" : "#000" }} >Swap</StyledAbsoluteLinkA></Link> |
        <Link to="/pool"><StyledAbsoluteLinkA style={{ "color": isDark ? "#ffff" : "#000" }} >Pool</StyledAbsoluteLinkA></Link> |
        <Link to="/chart"><StyledAbsoluteLinkA style={{ "color": isDark ? "#ffff" : "#000" }} >Chart</StyledAbsoluteLinkA></Link> |
        <StyledAbsoluteLinkA style={{ "color": isDark ? "#ffff" : "#000" }} href="https://www.binance.com/en/nft/profile/birdnfts-65f76e7e19cfbce1a98e2d823facee95" target="_blank" >NFT</StyledAbsoluteLinkA> |
        <StyledAbsoluteLinkA style={{ "color": isDark ? "#ffff" : "#000" }} href="https://stake.macaron-swap.finance/" target="_blank" >STAKE</StyledAbsoluteLinkA> 
      </FLink01B>
    )
}


const StyledAbsoluteLinkA = styled.a`
    cursor:pointer; 
    text-decoration: none;
    color:#fff;
    font-size:13px;
    margin:0 16px;
    &:hover {
        color: #F3B92E;
    }
`


const FooterFrame = styled.footer`

    background-color:transparent;
    width:100%;
    height:100px; 
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    position:relative;
    @media (min-width: 320px) and (max-width: 500px){
        flex-wrap: wrap;
        flex-direction: row;
    }
    @media (min-width: 501px) and (max-width: 767px){
        height:auto;
        
    }
    @media (min-width: 768px) and (max-width: 991px){
        height:auto;
    } 
`



const FooterCbox = styled.div`
    text-align:center;
    margin:0 auto;
`

const FLink01B = styled.div`
    
    padding-bottom:8px;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (min-width: 600px) {
    display: none;
  }
`






const FooterBody = styled.div`
    display:block;
    width:99%;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 30px;
    z-index : 10;
    

`
const Footer: React.FC = () => {
    
    return (
        <FooterBody>
            <FooterFrame>
                <FooterCbox>
                    <Link01 />
                </FooterCbox>
            </FooterFrame>
        </FooterBody>
    )}

export default Footer